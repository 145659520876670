export default defineNuxtRouteMiddleware((to, _from) => {
  if (import.meta.server) return; // only run on the client, the server knows nothing about my auth state
  const user = useUser();

  if (user.value.user === null) {
    // No user, so head to login screen
    return navigateTo(`/login?referrer=${encodeURIComponent(to.path)}`);
  }

  if (useIsActive() === false && !to.path.startsWith(`/membership`)) {
    // User is not active, so head to membership screen
    return navigateTo(`/membership`);
  }
});
